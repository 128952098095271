/**
 * Moves an item in an array up by one position.
 * If the item is already at the top (index 0), the array remains unchanged.
 *
 * @param {number} indexOfItem - The index of the item to move.
 * @param {Array} array - The array containing the item.
 * @returns {Array} - A new array with the item moved up by one position if possible,
 * or the original array if the item is already at the top.
 */
function moveItemUp(indexOfItem, array) {
  if (indexOfItem === 0) {
    return array;
  }
  const arrayCopy = [...array];
  const item = arrayCopy[indexOfItem];
  const itemAbove = arrayCopy[indexOfItem - 1];
  arrayCopy[indexOfItem - 1] = item;
  arrayCopy[indexOfItem] = itemAbove;
  return arrayCopy;
}

/**
 * Moves an item in an array up by one position.
 * If the item is already at the top (index 0), the array remains unchanged.
 *
 * @param {number} indexOfItem - The index of the item to move.
 * @param {Array} array - The array containing the item.
 * @returns {Array} - A new array with the item moved up by one position if possible,
 *  or the original array if the item is already at the top.
 */
function moveItemDown(indexOfItem, array) {
  if (indexOfItem === array.length - 1) {
    return array;
  }
  const arrayCopy = [...array];
  const item = arrayCopy[indexOfItem];
  const itemAbove = arrayCopy[indexOfItem + 1];
  arrayCopy[indexOfItem + 1] = item;
  arrayCopy[indexOfItem] = itemAbove;
  return arrayCopy;
}

export { moveItemDown, moveItemUp };
