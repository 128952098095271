import { useEffect, useState } from 'react';
import { moveItemDown, moveItemUp } from 'utils/moveItem';
import { v4 as uuidv4 } from 'uuid';

export async function getBriefingsFirestore(firebase, eventId) {
  const db = firebase.firestore();
  const briefingSnapshot = await db
    .collection('briefings')
    .where('eventId', '==', eventId)
    .get();
  const briefingData = briefingSnapshot.docs.map((doc) => doc.data());
  return briefingData;
}

export async function saveBriefingFirestore(firebase, briefing) {
  const db = firebase.firestore();
  await db
    .collection('briefings')
    .doc(briefing.id)
    .set(briefing, { merge: true });
}

export async function deleteBriefingFirestore(firebase, briefingId) {
  await firebase.firestore().collection('briefings').doc(briefingId).delete();
}

export async function saveMultipleBriefingsFirestore(firebase, briefings) {
  const promises = briefings.map((briefing) => {
    return saveBriefingFirestore(firebase, briefing);
  });
  await Promise.all(promises);
}

export async function deleteMultipleBriefingsFirestore(firebase, briefingsIds) {
  const promises = briefingsIds.map((briefingId) =>
    deleteBriefingFirestore(firebase, briefingId),
  );
  await Promise.all(promises);
}

/**
 * Sort an array of objects based on the `dateCreated` property and move objects
 * without the `dateCreated` property to the end of the array.
 *
 * @param {Object} x - The first object to compare.
 * @param {Object} y - The second object to compare.
 * @returns {number} A negative value if `x` should come before `y`, a positive
 * value if `y` should come before `x`, or 0 if their order should remain unchanged.
 */
function sortByDateCreatedOrAppendToEnd(x, y) {
  if (x.hasOwnProperty('dateCreated') && y.hasOwnProperty('dateCreated')) {
    return y.dateCreated - x.dateCreated;
  } else if (x.hasOwnProperty('dateCreated')) {
    return -1;
  } else if (y.hasOwnProperty('dateCreated')) {
    return 1;
  } else {
    return 0;
  }
}

// Custom hook for managing briefings and topics state
export function useBriefings(firebase, eventId) {
  const [briefings, setBriefings] = useState([]);
  const [deletedBriefingsIds, setDeletedBriefingsIds] = useState([]);

  useEffect(() => {
    async function fetchBriefings() {
      if (!eventId) {
        return;
      }
      const fetchedBriefings = await getBriefingsFirestore(firebase, eventId);
      fetchedBriefings.sort(sortByDateCreatedOrAppendToEnd);
      setBriefings(fetchedBriefings);
    }

    fetchBriefings();
  }, [eventId, firebase]);

  // BRIEFINGS

  function addBriefing() {
    // Create new briefing id
    const briefingRef = firebase.firestore().collection('briefings').doc();
    const newBriefing = {
      id: briefingRef.id,
      eventId,
      title: '',
      // for backwards compatibility topics are called briefings here
      briefings: [],
      rolesIds: ['All'],
      dateCreated: new Date(),
    };
    setBriefings([newBriefing, ...briefings]);
  }

  function replaceBriefing(updatedBriefing) {
    const newBriefings = briefings.map((briefing) => {
      if (briefing.id === updatedBriefing.id) {
        return updatedBriefing;
      } else {
        return briefing;
      }
    });
    setBriefings(newBriefings);
  }

  function editBriefing(editedBriefing, keyName, newValue) {
    const newBriefing = { ...editedBriefing, [keyName]: newValue };
    replaceBriefing(newBriefing);
  }

  function deleteBriefing(briefingId) {
    setBriefings(briefings.filter((briefing) => briefing.id !== briefingId));
    setDeletedBriefingsIds([...deletedBriefingsIds, briefingId]);
  }

  // TOPICS

  /**
   * Adds a new topic to the edited briefing.
   * @param {Object} editedBriefing - The briefing to which the topic will be added.
   * @param {string} topicType - The type of topic to add ('regular' or 'pdf').
   *
   * @returns {Object} The newly added topic object.
   */
  function addTopic(editedBriefing, topicType = 'regular') {
    const newTopic = {
      key: uuidv4(),
      html: '',
      status: 'unpublished',
      title: '',
    };
    if (topicType === 'pdf') {
      newTopic.pdf = '';
    }
    const newTopics = [...editedBriefing.briefings, newTopic];
    const newBriefing = { ...editedBriefing, briefings: newTopics };
    replaceBriefing(newBriefing);
    return newTopic;
  }

  /**
   * Edit a specific field of a topic within a briefing and update the briefing.
   *
   * @param {Object} editedBriefing - The edited briefing containing the topic.
   * @param {Object} editedTopic - The topic within the briefing to edit.
   * @param {string} keyName - The name of the field to edit
   *  (e.g., 'title', 'html', 'status', 'pdf').
   * @param {any} newValue - The new value to set for the specified field.
   */
  function editTopic(editedBriefing, editedTopic, keyName, newValue) {
    const updatedTopics = editedBriefing.briefings.map((topic) => {
      if (topic.key === editedTopic.key) {
        return { ...topic, [keyName]: newValue };
      } else {
        return topic;
      }
    });
    const newBriefing = { ...editedBriefing, briefings: updatedTopics };
    replaceBriefing(newBriefing);
  }

  function deleteTopic(editedBriefing, deletedTopic) {
    const updatedTopics = editedBriefing.briefings.filter(
      (topic) => topic.key !== deletedTopic.key,
    );
    const newBriefing = { ...editedBriefing, briefings: updatedTopics };
    replaceBriefing(newBriefing);
  }

  function moveTopic(editedBriefing, movedTopic, callback) {
    const oldTopicsArray = editedBriefing.briefings;
    const movedTopicIndex = oldTopicsArray.findIndex(
      (topic) => topic.key === movedTopic.key,
    );
    const newTopicsArray = callback(movedTopicIndex, oldTopicsArray);
    const newBriefing = { ...editedBriefing, briefings: newTopicsArray };
    replaceBriefing(newBriefing);
  }

  function moveTopicUp(editedBriefing, movedTopic) {
    moveTopic(editedBriefing, movedTopic, moveItemUp);
  }

  function moveTopicDown(editedBriefing, movedTopic) {
    moveTopic(editedBriefing, movedTopic, moveItemDown);
  }

  async function saveBriefingsChangesFirestore() {
    await Promise.all([
      saveMultipleBriefingsFirestore(firebase, briefings),
      deleteMultipleBriefingsFirestore(firebase, deletedBriefingsIds),
    ]);
  }

  return {
    briefings,
    addBriefing,
    editBriefing,
    deleteBriefing,
    addTopic,
    editTopic,
    deleteTopic,
    moveTopicUp,
    moveTopicDown,
    saveBriefingsChangesFirestore,
  };
}
