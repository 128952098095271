/**
 * Firestore Structure for Read Briefings
 * ---------------------------------------
 * Collection: 'readBriefings'
 * - Each document in this collection indicates a briefing that has been read by a user.
 *
 * Document Fields:
 * - userId (string): Identifies the user whose progress is being tracked.
 * - briefingId (string): Identifies the briefing that the user read.
 * - eventId (string): Identifies the event to which the briefing belongs.
 * - readTopics (array): An array containing topic keys indicating which topics have been read
 * by the user.
 * - id (string): A unique identifier for the document (randomly generated).
 */

import { filterBriefingsByRole } from '../utils/rolesUtils';

/**
 * Retrieves read briefings for a specific event from Firestore.
 */

export async function getReadBriefingsByEventIdFirestore(firebase, eventId) {
  const db = firebase.firestore();
  const snapshot = await db
    .collection('readBriefings')
    .where('eventId', '==', eventId)
    .get();
  const data = snapshot.docs.map((doc) => doc.data());

  return filterOldReadBriefings(data);
}

export async function getUserReadBreifingsFirestore(firebase, userId, eventId) {
  const db = firebase.firestore();
  const snapshot = await db
    .collection('readBriefings')
    .where('eventId', '==', eventId)
    .where('userId', '==', userId)
    .get();
  const data = snapshot.docs.map((doc) => doc.data());
  return filterOldReadBriefings(data);
}

/**
 * Because there should be only one readBriefing document per user and briefing
 * We generate the id using respective ids.
 */
function getReadBriefingsId(userId, briefingId) {
  return `${userId}-${briefingId}`;
}

/**
 * Reads a topic in Firebase, updating the 'readTopics' array in the 'readBriefings' collection.
 */
export async function readTopicFirebase(
  firebase,
  userId,
  companyId,
  briefingId,
  topicId,
  eventId,
) {
  const readBriefingsId = getReadBriefingsId(userId, briefingId);
  const ref = firebase
    .firestore()
    .collection('readBriefings')
    .doc(readBriefingsId);

  const doc = await ref.get();
  if (doc.exists) {
    // update
    const { arrayUnion } = firebase.firestore.FieldValue;
    await ref.update({
      readTopics: arrayUnion(topicId),
    });
  } else {
    // create
    await ref.set({
      id: readBriefingsId,
      readTopics: [topicId],
      userId,
      briefingId,
      companyId,
      eventId,
    });
  }
}

/**
 * Adds a topic to the 'readTopics' array for a specific briefing in the 'readBriefings' array.
 *
 */

export function addTopicToReadBriefing(readBriefings, briefingId, topicId) {
  readBriefings.map((readBriefing) => {
    if (readBriefing.briefingId === briefingId) {
      const readTopics = [...readBriefing.readTopics, topicId];
      return { ...readBriefing, readTopics };
    } else {
      return readBriefing;
    }
  });
  return readBriefings;
}

/**
 * Checks if a specific topic has already been read in the 'readBriefings'
 * array for a given briefing.
 */
export function isTopicAlreadyRead(readBriefings, briefingId, topicId) {
  const relevantReadBriefing = readBriefings.find(
    (readBriefing) => readBriefing.briefingId === briefingId,
  );
  return relevantReadBriefing?.readTopics?.includes(topicId) ?? false;
}

export function howManyTopicsWorkerRead(readBriefings, workerTopics, workerId) {
  const userReadBriefings = readBriefings.filter(
    (readBriefing) => readBriefing.userId === workerId,
  );
  const allTopicsRead = userReadBriefings.flatMap(
    (readBriefing) => readBriefing.readTopics,
  );
  const allTopicsKeys = workerTopics.map((topic) => topic.key);

  const allTopicsReadThatStillExist = allTopicsRead.filter((topicId) =>
    allTopicsKeys.includes(topicId),
  );
  return allTopicsReadThatStillExist?.length ?? 0;
}

/**
 * Retrieves all topics assigned to a worker from the provided briefings
 * based on role and companyId.
 */
export function getAllWorkerTopics(briefings, worker, companyId) {
  const workerBriefings = filterBriefingsByRole(briefings, worker, companyId);
  const allTopics = workerBriefings.flatMap((briefing) => briefing.briefings);
  const publishedTopics = allTopics.filter(
    (topic) => topic.status === 'published',
  );
  return publishedTopics;
}

/**
 * Older readBriefings didn't have the 'readTopics' property,
 * I filter them out for proper calculations.
 * If you are sure that every document in the collection has readTopics property
 *  you can remove this filter.
 */
function filterOldReadBriefings(readBriefings) {
  return readBriefings.filter((readBriefing) =>
    Object.hasOwn(readBriefing, 'readTopics'),
  );
}
