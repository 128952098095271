import { getWorkerRoleId } from './objectGetters/workerGetters';

/**
 * Filters notifications to only show ones that should be visible to a worker,
 * based on the roles they belong to in the specified company.
 *
 * @param {Object} worker - The worker for whom notifications are being filtered.
 * @param {Array} notifications - The list of notifications to be filtered.
 * @returns {Array} - An array of notifications that are applicable to the worker.
 */
export function filterNotificationsPerRoles(worker, notifications) {
  return notifications.filter((notification) => {
    const isForAllRoles =
      notification?.sendToAllRoles === undefined ||
      notification?.sendToAllRoles === true;

    if (isForAllRoles) {
      return true;
    }

    const workerRoleId = getWorkerRoleId(worker, notification.companyId);

    // If the worker doesn't have any applicable role in this company,
    // don't show them the notification.
    if (!workerRoleId) {
      return false;
    }

    const notificationRolesIds = notification.rolesIds;

    return notificationRolesIds.includes(workerRoleId);
  });
}

export function filterBriefingsByRole(allBriefings, user, companyId) {
  const briefingsFilteredByRole = allBriefings.filter((briefing) => {
    const isForAllRoles =
      !briefing?.rolesIds || briefing.rolesIds.includes('All');
    if (isForAllRoles) {
      return true;
    } else {
      const userRole = getWorkerRoleId(user, companyId);
      return briefing.rolesIds.includes(userRole);
    }
  });
  return briefingsFilteredByRole;
}
